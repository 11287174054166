import {
  addFlow,
  deleteFlow,
  getFlowById,
  getFlows,
  updateFlow,
  getFlowsForProfile,
} from 'types/api/services/flow/flow';

/** FlowService */
export class FlowService {
  /** Get flows : (ADMIN) Retrieve the list of flows available for assigning to candidates */
  static getFlows = getFlows;

  /** Get flow by ID : (ADMIN) Retrieve an individual flow by ID */
  static getFlow = getFlowById;

  /** Create new flow : (ADMIN) Create a new flow consisting of tasks required to be completed by candidates*/
  static addFlow = addFlow;

  /** Update existing flow : (ADMIN) Update an existing flow */
  static updateFlow = updateFlow;

  /** Delete existing flow : (ADMIN) Delete an existing flow*/
  static deleteFlow = deleteFlow;

  /** Get flows for profile : (ADMIN) Retrieve the list of flows available for assigning to candidates using profile */
  static getFlowsForProfile = getFlowsForProfile;
}
