/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */
import type {
  WorkerModel,
  WorkerProfilesModel,
  GetAllWorkerProfilesParams,
  ResponseEntity,
  UploadProfilePictureAdminImageBody,
  UploadProfilePictureParams,
  GetWorkerProfilesParams,
} from '../..';
import getWorkerProfileMutator from '../../../../utils/HttpClientOrval';
import getAllWorkerProfilesMutator from '../../../../utils/HttpClientOrval';
import retrieveProfilePictureMutator from '../../../../utils/HttpClientOrval';
import uploadProfilePictureMutator from '../../../../utils/HttpClientOrval';
import getWorkerProfilesMutator from '../../../../utils/HttpClientOrval';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * Get worker profile from worker guid
 * @summary Get worker profile
 */
export const getWorkerProfile = (
  options?: SecondParameter<typeof getWorkerProfileMutator>
) => {
  return getWorkerProfileMutator<WorkerModel>(
    { url: `/worker`, method: 'get' },
    options
  );
};
/**
 * Get all profiles from worker guid and status
 * @summary Get all profiles for worker
 */
export const getAllWorkerProfiles = (
  params: GetAllWorkerProfilesParams,
  options?: SecondParameter<typeof getAllWorkerProfilesMutator>
) => {
  return getAllWorkerProfilesMutator<WorkerProfilesModel>(
    { url: `/worker/all-profiles`, method: 'get', params },
    options
  );
};
/**
 * Retrieve profile picture of logged in candidate
 * @summary Retrieve profile picture of logged in candidate
 */
export const retrieveProfilePicture = (
  options?: SecondParameter<typeof retrieveProfilePictureMutator>
) => {
  return retrieveProfilePictureMutator<string>(
    { url: `/worker/profile-image`, method: 'get' },
    options
  );
};
/**
 * Upload profile picture for logged in candidate
 * @summary Upload profile picture for logged in candidate
 */
export const uploadProfilePicture = (
  uploadProfilePictureAdminImageBody: UploadProfilePictureAdminImageBody,
  params?: UploadProfilePictureParams,
  options?: SecondParameter<typeof uploadProfilePictureMutator>
) => {
  return uploadProfilePictureMutator<ResponseEntity>(
    {
      url: `/worker/profile-image`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: uploadProfilePictureAdminImageBody,
      params,
    },
    options
  );
};
/**
 * Get profiles from worker guid and status
 * @summary Get profiles for worker
 */
export const getWorkerProfiles = (
  params: GetWorkerProfilesParams,
  options?: SecondParameter<typeof getWorkerProfilesMutator>
) => {
  return getWorkerProfilesMutator<WorkerProfilesModel>(
    { url: `/worker/profiles`, method: 'get', params },
    options
  );
};
