import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

import Loading from 'components/Loading/Loading';
import { useConfiguration } from 'hooks/api/config';
import GeneralError from 'pages/GeneralError/GeneralError';
import { ConfigModel } from 'types/api';

interface ConfigContextInterface {
  config: ConfigModel | undefined;
  getConfig: () => void;
}

const ConfigContext = createContext({} as ConfigContextInterface);
ConfigContext.displayName = 'ConfigContext';

export const ConfigProvider = ({ children }: { children: ReactNode }) => {
  const [fetch, setFetch] = useState(0);
  const { isLoading, error, config } = useConfiguration(fetch);

  const getConfig = () => {
    setFetch(Date.now());
  };

  const values = useMemo(
    () => ({
      config,
      getConfig,
    }),
    [config]
  );

  if (error) {
    return <GeneralError error={error as { message: '' }} />;
  }

  return isLoading ? (
    <div className="vh-100 vw-100 d-flex justify-content-center align-items-center">
      <Loading />
    </div>
  ) : (
    <ConfigContext.Provider value={values}>{children}</ConfigContext.Provider>
  );
};

export const useConfigContext = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfigContext must be used within a ConfigProvider');
  }
  return context;
};
