import { MouseEventHandler } from 'react';
import { Navbar } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import useTheme from 'hooks/useTheme';

const GeneralError = ({
  error,
  resetErrorBoundary,
}: {
  error: { message: string };
  resetErrorBoundary?: MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  const { currentTheme } = useTheme();

  return (
    <>
      {currentTheme && (
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            href={`${process.env.REACT_APP_BASE_URL}/themes/${currentTheme}/theme.css`}
          />
          <link
            rel="icon"
            type="image/png"
            href={`${process.env.REACT_APP_BASE_URL}/themes/favicon/${currentTheme}-favicon-32x32.png`}
          />
        </Helmet>
      )}
      <div className="user page">
        <Navbar className="header justify-content-between align-items-center">
          <div className="container container-xxl">
            <Navbar.Brand href="/" aria-label="Homepage">
              <div className="header__logo"></div>
            </Navbar.Brand>
          </div>
        </Navbar>
        <main className="main main--user">
          <div className="home">
            <section className="home__header text-center">
              <h1 className="title mt-8">Oops</h1>
              <h1 style={{ color: 'var(--color-page-header)' }}>
                Something went wrong
              </h1>
              <p className="h1-alt">{error.message}</p>

              <button
                className="button button--primary"
                onClick={resetErrorBoundary}
              >
                Go back to Home
              </button>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default GeneralError;
