/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */
import type {
  DocumentDetailsResponse,
  DocumentOCRBody,
  DocumentOCRParams,
  ValidateDocumentResponse,
  ValidateDriversLicenceRequest,
  ValidateDriversLicenceParams,
  ValidatePassportRequest,
  ValidatePassportParams,
  SupportedDocumentTypeResponse,
} from '../..';
import documentOCRMutator from '../../../../utils/HttpClientOrval';
import validateDriversLicenceMutator from '../../../../utils/HttpClientOrval';
import validatePassportMutator from '../../../../utils/HttpClientOrval';
import supportedDocumentTypesMutator from '../../../../utils/HttpClientOrval';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * Upload image of identity document for Optical Character Recognition. Retrieve list of supported document types for document_id
 * @summary Upload image of document for Optical Character Recognition
 */
export const documentOCR = (
  documentOCRBody: DocumentOCRBody,
  params: DocumentOCRParams,
  options?: SecondParameter<typeof documentOCRMutator>
) => {
  const formData = new FormData();
  formData.append('front', documentOCRBody.front);
  if (documentOCRBody.back !== undefined) {
    formData.append('back', documentOCRBody.back);
  }

  return documentOCRMutator<DocumentDetailsResponse>(
    {
      url: `/document-validation/document-ocr`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
      params,
    },
    options
  );
};
/**
 * Validate drivers license for logged in candidate
 * @summary Validate drivers license for logged in candidate
 */
export const validateDriversLicence = (
  validateDriversLicenceRequest: ValidateDriversLicenceRequest,
  params?: ValidateDriversLicenceParams,
  options?: SecondParameter<typeof validateDriversLicenceMutator>
) => {
  return validateDriversLicenceMutator<ValidateDocumentResponse>(
    {
      url: `/document-validation/drivers-licence`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: validateDriversLicenceRequest,
      params,
    },
    options
  );
};
/**
 * Validate passport for logged in candidate
 * @summary Validate passport for logged in candidate
 */
export const validatePassport = (
  validatePassportRequest: ValidatePassportRequest,
  params?: ValidatePassportParams,
  options?: SecondParameter<typeof validatePassportMutator>
) => {
  return validatePassportMutator<ValidateDocumentResponse>(
    {
      url: `/document-validation/passport`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: validatePassportRequest,
      params,
    },
    options
  );
};
/**
 * Retrieve list of supported document types for identity validation
 * @summary Retrieve list of supported document types
 */
export const supportedDocumentTypes = (
  options?: SecondParameter<typeof supportedDocumentTypesMutator>
) => {
  return supportedDocumentTypesMutator<SupportedDocumentTypeResponse[]>(
    { url: `/document-validation/supported-document-types`, method: 'get' },
    options
  );
};
