import { AdditionalInformation } from './additionalInformation';
import { Document } from './document';
import { FlowProgress } from './flow';
import { ProfileContract } from './profileContract';
import { Qualification } from './qualification';
import { PersonSkill, SkillType } from './skill';
import { SuperFundDetails } from './superfund';
import { TaskProgress } from './task';
import { WorkRightsInfo } from './workRightInfo';

enum ABNStatus {
  SUBMITTED = 'SUBMITTED',
  VALID = 'VALID',
  INVALID = 'INVALID',
  REFUSED = 'REFUSED',
  ERROR = 'ERROR',
}

interface ABNDetails {
  abn: string;
  abnStatus: ABNStatus;
  abnErrorCode: string;
  abnErrorMessage: string;
  abnRequestedRef: string;
  abnApplicationRef: string;
}

enum BankCodeIdentifier {
  SWIFT = 'SWIFT',
  SORT_CODE = 'SORT_CODE',
  ABA_ROUTING = 'ABA_ROUTING',
}

interface BankDetails {
  bsb: string;
  accountName: string;
  accountNumber: string;
  identifier: BankCodeIdentifier;
  bankCode: string;
  bankName: string;
}

interface BusinessDetails {
  tradingName: string;
  legalName: string;
  abn: string;
  addressLine1: string;
  addressLine2: string;
  addressLocality: string;
  addressPostcode: string;
  addressState: string;
  addressCountry: string;
  contactName: string;
  contactTelephone: string;
  website: string;
  acn: string;
}

export enum PersonStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  COMPLETED = 'COMPLETED',
  NO_FLOW = 'NO_FLOW',
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
}

export enum CitizenshipType {
  AUSTRALIAN_CITIZEN = 'AUSTRALIAN_CITIZEN',
  NON_AUSTRALIAN_CITIZEN = 'NON_AUSTRALIAN_CITIZEN',
  PRE_EXISTING_USER = 'PRE_EXISTING_USER',
  NOT_PROVIDED = 'NOT_PROVIDED',
}

export interface Person {
  abnDetails: ABNDetails;
  additionalInformation: AdditionalInformation[];
  addressCountry: string;
  addressLine1: string;
  addressLine2: string;
  addressLocality: string;
  addressPostcode: string;
  addressState: string;
  bankDetails: BankDetails;
  birthDate: string;
  businessDetails: BusinessDetails;
  claimTaxFreeThreshold: boolean;
  contract: ProfileContract;
  documents: Document[];
  email: string;
  emergencyContactName: string;
  emergencyContactPhoneNumber: string;
  externalId: string;
  familyName: string;
  flowProgresses: FlowProgress[];
  fromProfile?: string;
  gender: string;
  givenName: string;
  guid: string;
  linkedinProfile: string;
  location: string;
  middleName: string;
  paymentBasis: string;
  personSkillTypes: SkillType[];
  personSkills: PersonSkill[];
  phoneNumber: string;
  position: string;
  previousFamilyName: string;
  pushedAt: string;
  qualifications: Qualification[];
  residencyStatus: string;
  status: PersonStatus;
  studentLoanDebt: boolean;
  superFundDetails: SuperFundDetails;
  taskProgresses: TaskProgress[];
  taxFileNumber: string;
  taxFileNumberDeclarationSubmitted: boolean;
  taxFileNumberValid: boolean;
  tfnExemptionCode: string;
  toProfile: string;
  updatedAt: string;
  workRightsInfo: WorkRightsInfo;
  workerGuid: string;
  citizenshipType: CitizenshipType;
  fromContractWorkOrderNumber: string;
  archivedReason: string;
  isAdditionalDocumentCompleted?: boolean;
}
