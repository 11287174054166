/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */

export type AuditSearchRequestAuditEventListItem =
  (typeof AuditSearchRequestAuditEventListItem)[keyof typeof AuditSearchRequestAuditEventListItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuditSearchRequestAuditEventListItem = {
  PROFILE_CREATED: 'PROFILE_CREATED',
  PROFILE_UPDATED: 'PROFILE_UPDATED',
  PROFILE_CREATED_BULK_UPLOAD: 'PROFILE_CREATED_BULK_UPLOAD',
  PROFILE_DELETED: 'PROFILE_DELETED',
  PATH_ASSIGNED: 'PATH_ASSIGNED',
  PATH_UNASSIGNED: 'PATH_UNASSIGNED',
  ID_CHECK: 'ID_CHECK',
  WORK_RIGHTS_CHECK: 'WORK_RIGHTS_CHECK',
  AHPRA_CHECK: 'AHPRA_CHECK',
  DOCUMENT_UPLOADED: 'DOCUMENT_UPLOADED',
  DOCUMENT_REMOVED: 'DOCUMENT_REMOVED',
  DOCUMENT_TEMPLATES_ASSIGNED: 'DOCUMENT_TEMPLATES_ASSIGNED',
  TASK_APPROVED: 'TASK_APPROVED',
  TASK_REJECTED: 'TASK_REJECTED',
  DOCUMENT_SIGNED: 'DOCUMENT_SIGNED',
  PAYROLL_PUSH: 'PAYROLL_PUSH',
  WORKING_WITH_CHILDREN: 'WORKING_WITH_CHILDREN',
  BULK_UPLOAD_VALIDATE: 'BULK_UPLOAD_VALIDATE',
  BULK_UPLOAD_SUBMIT: 'BULK_UPLOAD_SUBMIT',
  PROFILE_ACTIVATED: 'PROFILE_ACTIVATED',
  PROFILE_DEACTIVATED: 'PROFILE_DEACTIVATED',
} as const;
