import FormSelect from 'components/FormSelect/FormSelect';
import { useHierarchy } from 'contexts/hierarchyContext';
import { TenantModel } from 'types/api';

export interface CompanyOption {
  value: string;
  label: string;
  icon?: string;
}

export const mapTenantToOption = (tenant: TenantModel) => {
  const { channel, name } = tenant;
  return {
    value: channel,
    label: name,
  };
};

interface CompaniesSelectMenu {
  isDisabled: boolean;
}

const CompaniesSelectMenu = ({ isDisabled }: CompaniesSelectMenu) => {
  const { companyList, selectedCompany, updateSelectedCompany } =
    useHierarchy();

  const companyOptionList = companyList
    ? companyList.map(mapTenantToOption)
    : [];

  return (
    <>
      {companyOptionList?.length > 0 && (
        <FormSelect
          name="companiesList"
          isDisabled={isDisabled}
          options={companyOptionList}
          value={selectedCompany}
          onChange={(option: CompanyOption) => {
            updateSelectedCompany(option);
          }}
          className="nav__select"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          menuPortalTarget={document.querySelector('.modal')}
          placeholder="Search..."
          isSearchable={true}
          id={'company-list'}
        />
      )}
    </>
  );
};

export default CompaniesSelectMenu;
