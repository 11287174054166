import {
  activateProfileUsingPOST,
  addFlowToPerson,
  addWorkOrderNumberUsingPOST,
  approveDecisionTool,
  approveDocument,
  calculateRate,
  cancelProfileUsingPUT,
  createCandidate,
  createOrUpdateProfileWithContractUsingPOST,
  createOrUpdateProfileWithEngagementUsingPOST,
  createOrUpdateProfileWithRateUsingPOST,
  createOrUpdateProfileWithWorksiteUsingPOST,
  createProfile,
  deactivateProfileUsingPOST,
  deleteOriginalDocumentAdmin,
  getDefaultTasks,
  getProfileUsingGET,
  getWorkerId,
  rejectDecisionTool,
  rejectDocument,
  removeFlowFromPerson,
  removeProfileUsingDELETE,
  retrieveOriginalDocumentAdmin,
  retrieveProfilesUsingGET,
  searchWorkers,
  updateDocumentAdmin,
  updateIsAdditionalDocumentCompletedUsingPUT,
  updateProfileUsingPUT,
  updateTimesheet,
  uploadDocumentAdmin,
} from 'types/api/services/worker-admin/worker-admin';

/** WorkerAdminService */
export class WorkerAdminService {
  /**
   * (ADMIN) Add additional flow for candidate to complete
   * @summary Add flow to candidate
   */
  static addFlowToPerson = addFlowToPerson;

  /**
   * (ADMIN) Delete existing flow from candidate
   * @summary Delete flow from candidate
   */
  static deleteFlowFromPerson = removeFlowFromPerson;

  /**
   * Upload original document for candidate
   * @summary Upload original document for worker profile
   */
  static uploadDocumentAdmin = uploadDocumentAdmin;

  /**
   * (ADMIN) Approve user uploaded document after reviewing. This is for tasks that require manual document approval to be marked as complete
   * @summary Approve user uploaded document
   */
  static approveDocument = approveDocument;

  /**
   * Retrieve original document provided to the candidate to amend and upload
   * @summary Retrieve original document
   */
  static retrieveOriginalDocumentAdmin = retrieveOriginalDocumentAdmin;

  /**
   * Update existing original document for candidate
   * @summary Update existing original document for candidate
   */
  static updateDocumentAdmin = updateDocumentAdmin;

  /**
   * Reject user uploaded document after reviewing with a reason. This is for tasks that require manual document approval to be marked as complete
   * @summary Reject user uploaded document
   */
  static rejectDocument = rejectDocument;

  /**
   * (ADMIN) Get a worker guid
   * @summary Get new worker id for creating workers
   */
  static getWorkerId = getWorkerId;

  /**
   * (ADMIN) Get a worker guid
   * @summary Get workers in the channel BY status
   */
  static searchWorkers = searchWorkers;

  /**
   * (ADMIN) Retrieve the list of default tasks available for the employer
   * @summary Get Employer default tasks
   */
  static getTasks = getDefaultTasks;

  /**
   * (ADMIN) Creates profile for a candidate
   * @summary Create candidate profile
   */
  static createProfile = createProfile;

  /**
   * Gets profile by guid
   * @summary getProfile
   *
   */
  static getProfile = getProfileUsingGET;

  /**
   * (ADMIN) Updates profile for a candidate
   * @summary  Update candidate profile
   */
  static updateProfile = updateProfileUsingPUT;

  /**
   * (Admin): Remove profile for a candidate
   * @summary removeProfile
   */
  static removeProfile = removeProfileUsingDELETE;

  /**
   * (Admin): Activate profile
   * @summary activateProfile
   */
  static activateProfile = activateProfileUsingPOST;

  /** (Admin):
   * @summary updateIsAdditionalDocumentCompleted
   */
  static updateIsAdditionalDocumentCompleted =
    updateIsAdditionalDocumentCompletedUsingPUT;

  /**
   * (ADMIN) Calculate the admin and other rates for the candidate
   * @summary Get rates calculated
   */
  static calculateRate = calculateRate;

  /**
   * (Admin): Cancel profile
   * @summary cancelProfile
   */
  static cancelProfile = cancelProfileUsingPUT;

  /**
   * (Admin): ? //TODO add description
   * @summary createOrUpdateProfileWithContract
   */
  static createOrUpdateProfileWithContract =
    createOrUpdateProfileWithContractUsingPOST;

  /**
   * //TODO add description
   * @summary addWorkOrderNumber
   */
  static addWorkOrderNumber = addWorkOrderNumberUsingPOST;

  /**
   * //TODO add description
   * @summary deactivateProfile
   */
  static deactivateProfile = deactivateProfileUsingPOST;

  /**
   * Delete original document
   * @summary Delete original document
   */
  static deleteOriginalDocument = deleteOriginalDocumentAdmin;

  /**
   * //TODO add description
   * @summary createOrUpdateProfileWithEngagement
   */
  static createOrUpdateProfileWithEngagement =
    createOrUpdateProfileWithEngagementUsingPOST;

  /**
   * Create the cadidate on the vendor payroll system
   * @summary Create candidate on payroll system
   */
  static createCandidate = createCandidate;

  /**
   * @summary createOrUpdateProfileWithRate
   */
  static createOrUpdateProfileWithRate = createOrUpdateProfileWithRateUsingPOST;

  /**
   * (ADMIN) Update the timesheet information for the candidate
   * @summary Update Timesheet
   */
  static updateTimesheet = updateTimesheet;

  /**
   * @summary createOrUpdateProfileWithWorksite
   */
  static createOrUpdateProfileWithWorksite =
    createOrUpdateProfileWithWorksiteUsingPOST;

  /**
   * @summary retrieveProfiles
   */
  static retrieveProfiles = retrieveProfilesUsingGET;

  /**
   * (ADMIN) approve decision tool
   * @summary approve decision tool
   */
  static approveDecisionTool = approveDecisionTool;

  /**
   * (ADMIN) reject decision tool
   * @summary reject decision tool
   */
  static rejectDecisionTool = rejectDecisionTool;
}
