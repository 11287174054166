import { useQuery } from '@tanstack/react-query';

import { ConfigService } from 'services';

export const useConfiguration = (fetch: number) => {
  const { isLoading, isError, error, data } = useQuery(
    ['my-config', fetch],
    async () => {
      const { data } = await ConfigService.getConfig();
      return data;
    }
  );

  return {
    isLoading,
    isError,
    error,
    config: data,
  };
};
