import {
  getAbnQuestionnaire,
  lookupAbn,
  registerAbn,
  searchAbn,
} from 'types/api/services/abn/abn';

/** AbnService */
export class AbnService {
  /** Search ABR for ABN : Search ABR for details of ABN and validate response */
  static searchAbn = searchAbn;

  /** Lookup ABR for ABN : Search ABR for details of ABN */
  static lookupAbn = lookupAbn;

  /** Retrieve ABN Registration Questionnaire : Retrieve the Australian Business Number Registration Get ABN Entitlement Questionnaire*/
  static getAbnQuestionnaire = getAbnQuestionnaire;

  /** Register business for ABN : Submit business registration application to ABR*/
  static registerAbn = registerAbn;
}
