/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */
import type { ValidateBsbResponse } from '../..';
import validateBSBMutator from '../../../../utils/HttpClientOrval';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * Validate bank account BSB against list of known valid BSBs from APCA
 * @summary Validate bank account BSB
 */
export const validateBSB = (
  bsb: string,
  options?: SecondParameter<typeof validateBSBMutator>
) => {
  return validateBSBMutator<ValidateBsbResponse>(
    { url: `/bsb-validation/${bsb}`, method: 'get' },
    options
  );
};
