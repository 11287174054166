import classNames from 'classnames';
import Select from 'react-select';

interface OptionType {
  [key: string]: any;
}
type OptionsType = Array<OptionType>;

interface FormSelectProps {
  className?: string;
  id: string;
  name: string;
  options: OptionsType;
  value?: any;
  onChange: (option: any) => void;
  menuPortalTarget?: Element;
  isDisabled?: boolean;
  onBlur?: () => void;
  [x: string]: any;
}

const FormSelect = ({ id, className, ...props }: FormSelectProps) => {
  return (
    <Select
      inputId={id}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      menuPortalTarget={document.querySelector('.page')}
      className={classNames('form-select-container', className)}
      classNamePrefix="form-select"
      placeholder="Please select..."
      {...props}
    />
  );
};

export default FormSelect;
