/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */

export type PersonModelIdCheckOption =
  (typeof PersonModelIdCheckOption)[keyof typeof PersonModelIdCheckOption];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PersonModelIdCheckOption = {
  PRIMARY_DOCUMENTS: 'PRIMARY_DOCUMENTS',
  SECONDARY_DOCUMENTS: 'SECONDARY_DOCUMENTS',
} as const;
