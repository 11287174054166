import {
  getBranchList,
  getCompanyList,
} from 'types/api/services/tenant/tenant';

/** TenantService */
export class TenantService {
  /** Get Branch List */
  static getBranchList = getBranchList;

  /** Get Company List */
  static getCompanyList = getCompanyList;
}
