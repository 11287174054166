/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */
import type {
  SuperFundDetailsResponse,
  RetrieveFundDetailsParams,
  FvsFund,
  SearchFundsParams,
} from '../..';
import retrieveFundDetailsMutator from '../../../../utils/HttpClientOrval';
import searchFundsMutator from '../../../../utils/HttpClientOrval';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * Retrieve Super fund details
 * @summary Retrieve Super fund details
 */
export const retrieveFundDetails = (
  params: RetrieveFundDetailsParams,
  options?: SecondParameter<typeof retrieveFundDetailsMutator>
) => {
  return retrieveFundDetailsMutator<SuperFundDetailsResponse>(
    { url: `/fvs/details`, method: 'get', params },
    options
  );
};
/**
 * Search ATO database of Super funds
 * @summary Search ATO database of Super funds
 */
export const searchFunds = (
  params: SearchFundsParams,
  options?: SecondParameter<typeof searchFundsMutator>
) => {
  return searchFundsMutator<FvsFund[]>(
    { url: `/fvs/search`, method: 'get', params },
    options
  );
};
