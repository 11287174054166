/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */

export type GetWorkerProfilesStatusesItem =
  (typeof GetWorkerProfilesStatusesItem)[keyof typeof GetWorkerProfilesStatusesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetWorkerProfilesStatusesItem = {
  IN_PROGRESS: 'IN_PROGRESS',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  COMPLETED: 'COMPLETED',
  NO_FLOW: 'NO_FLOW',
  ACTIVATED: 'ACTIVATED',
  DEACTIVATED: 'DEACTIVATED',
} as const;
