/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */
import type {
  CheckWorkRightsResponse,
  CheckWorkRightsRequest,
  CheckWorkRightsParams,
} from '../..';
import checkWorkRightsMutator from '../../../../utils/HttpClientOrval';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * Perform work rights check for logged in candidate
 * @summary Perform work rights check
 */
export const checkWorkRights = (
  checkWorkRightsRequest: CheckWorkRightsRequest,
  params?: CheckWorkRightsParams,
  options?: SecondParameter<typeof checkWorkRightsMutator>
) => {
  return checkWorkRightsMutator<CheckWorkRightsResponse>(
    {
      url: `/check-work-rights/create`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: checkWorkRightsRequest,
      params,
    },
    options
  );
};
