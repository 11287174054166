/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */

export type TimesheetRequestModelContractType =
  (typeof TimesheetRequestModelContractType)[keyof typeof TimesheetRequestModelContractType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimesheetRequestModelContractType = {
  INITIAL: 'INITIAL',
  EXTENSION: 'EXTENSION',
  AMENDMENT: 'AMENDMENT',
} as const;
