/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */
import type {
  EntryOfstringAndstring,
  RpaFormSubmitResult,
  WorkingWithChildrenValidationRequest,
  ValidateWorkingWithChildrenParams,
} from '../..';
import validStatesMutator from '../../../../utils/HttpClientOrval';
import validateWorkingWithChildrenMutator from '../../../../utils/HttpClientOrval';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * Retrieve list of valid States
 * @summary Retrieve list of valid States
 */
export const validStates = (
  options?: SecondParameter<typeof validStatesMutator>
) => {
  return validStatesMutator<EntryOfstringAndstring[]>(
    { url: `/working-with-children/states`, method: 'get' },
    options
  );
};
/**
 * Check validity of Working with Children check for logged in candidate
 * @summary Validate Working with Children check for logged in candidate
 */
export const validateWorkingWithChildren = (
  workingWithChildrenValidationRequest: WorkingWithChildrenValidationRequest,
  params?: ValidateWorkingWithChildrenParams,
  options?: SecondParameter<typeof validateWorkingWithChildrenMutator>
) => {
  return validateWorkingWithChildrenMutator<RpaFormSubmitResult>(
    {
      url: `/working-with-children/validate`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: workingWithChildrenValidationRequest,
      params,
    },
    options
  );
};
