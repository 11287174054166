import { useEffect, useState } from 'react';
import { Auth, I18n } from 'aws-amplify';
import {
  AmplifyAuthContainer,
  AmplifyAuthenticator,
  AmplifySignIn,
} from '@aws-amplify/ui-react';
import {
  AuthState,
  onAuthUIStateChange,
  Translations,
  UI_AUTH_CHANNEL,
  TOAST_AUTH_ERROR_EVENT,
} from '@aws-amplify/ui-components';
import { Hub } from '@aws-amplify/core';
import { AUTH_CONFIG, TOKEN_DISABLED } from './utils/configs';
import App from './App';
import { ErrorModal } from 'components/ErrorModal/ErrorModal';

I18n.putVocabulariesForLanguage('en-AU', {
  [Translations.BACK_TO_SIGN_IN]: 'Back to log in',
  [Translations.SIGN_IN_ACTION]: 'Log in',
  [Translations.FORGOT_PASSWORD_TEXT]: ' ',
  [Translations.RESET_PASSWORD_TEXT]: 'Forgot password',
  [Translations.SIGN_IN_HEADER_TEXT]: 'Log in to your account',
  [Translations.SIGN_OUT]: 'Log out',
  [Translations.VERIFY_CONTACT_HEADER_TEXT]: 'Choose your recovery option',
});
I18n.setLanguage('en-AU');

Auth.configure(AUTH_CONFIG);

const CustomAlert = () => {
  const [alertMessage, setAlertMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleToastErrors = ({ payload }) => {
    if (payload.event === TOAST_AUTH_ERROR_EVENT && payload.message) {
      setAlertMessage(payload.message);
      handleShowModal();
    }
  };

  useEffect(() => {
    Hub.listen(UI_AUTH_CHANNEL, handleToastErrors);
    return () => Hub.remove(UI_AUTH_CHANNEL, handleToastErrors);
  });

  return (
    <ErrorModal
      showModal={showModal}
      handleCloseModal={handleCloseModal}
      alertMessage={alertMessage}
    />
  );
};

const AuthStateApp = () => {
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <App />
  ) : (
    <div className="login">
      <div className="login__container">
        <div className="login__logo" />
        <CustomAlert />
        <AmplifyAuthContainer>
          <AmplifyAuthenticator hideToast>
            <AmplifySignIn slot="sign-in" hideSignUp={true} />
            <div slot="primary-footer-content" style={{ width: '100%' }} />
          </AmplifyAuthenticator>
        </AmplifyAuthContainer>
      </div>
    </div>
  );
};

const AppWithAuth = TOKEN_DISABLED ? App : AuthStateApp;

export default AppWithAuth;
