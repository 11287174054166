/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */

export type PersonModelCitizenshipType =
  (typeof PersonModelCitizenshipType)[keyof typeof PersonModelCitizenshipType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PersonModelCitizenshipType = {
  AUSTRALIAN_CITIZEN: 'AUSTRALIAN_CITIZEN',
  NON_AUSTRALIAN_CITIZEN: 'NON_AUSTRALIAN_CITIZEN',
  PRE_EXISTING_USER: 'PRE_EXISTING_USER',
  NOT_PROVIDED: 'NOT_PROVIDED',
} as const;
