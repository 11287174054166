import { Modal } from 'react-bootstrap';

interface ErrorModalProps {
  showModal: boolean;
  handleCloseModal: () => void;
  alertMessage: string;
}

export const ErrorModal = ({
  showModal,
  handleCloseModal,
  alertMessage,
}: ErrorModalProps) => {
  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      dialogClassName="modal-amplify__error"
    >
      <Modal.Header closeButton>{alertMessage}</Modal.Header>
    </Modal>
  );
};
