import {
  documentOCR,
  supportedDocumentTypes,
  validateDriversLicence,
  validatePassport,
} from 'types/api/services/document-validation/document-validation';

/** DocumentValidationService */
export class DocumentValidationService {
  /** Retrieve list of supported document types : Retrieve list of supported document types for identity validation */
  static supportedDocumentTypes = supportedDocumentTypes;

  /** Upload image of document for Optical Character Recognition :Upload image of identity document for Optical Character Recognition. Retrieve list of supported document types for document_id */
  static documentOCR = documentOCR;

  /** Validate drivers license for logged in candidate : Validate drivers license for logged in candidate */
  static validateDriversLicence = validateDriversLicence;

  /** Validate passport for logged in candidate : Validate passport for logged in candidate */
  static validatePassport = validatePassport;
}
