/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */

export type AHPRAInformationModelRegistrationStatus =
  (typeof AHPRAInformationModelRegistrationStatus)[keyof typeof AHPRAInformationModelRegistrationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AHPRAInformationModelRegistrationStatus = {
  REGISTERED: 'REGISTERED',
  UNREGISTERED: 'UNREGISTERED',
} as const;
