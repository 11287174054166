/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */
import type { GetTenantTokenParams } from '../..';
import getTenantTokenMutator from '../../../../utils/HttpClientOrval';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * Generate JWT token for given tenant
 * @summary Get tenant token
 */
export const getTenantToken = (
  selectedTenantChannel: string,
  userRoles: string,
  params?: GetTenantTokenParams,
  options?: SecondParameter<typeof getTenantTokenMutator>
) => {
  return getTenantTokenMutator<string>(
    {
      url: `/token/${selectedTenantChannel}/user-roles/${userRoles}`,
      method: 'get',
      params,
    },
    options
  );
};
