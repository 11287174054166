import { AxiosResponse } from 'axios';

import { PersonModel } from 'types/api';
import {
  acceptContractorTool,
  addQualification,
  copyOriginalDocumentToUserUsingPOST,
  deleteDocument,
  deleteQualification,
  retrieveOriginalDocument,
  retrieveSignedDocument,
  retrieveUserDocument,
  saveAdditionalInformation,
  signTask,
  updateDocument,
  updateIsAustralianCitizenUsingPUT,
  updatePerson,
  updatePersonBankDetails,
  updatePersonBankDetailsInternational,
  uploadDocument,
  updateIDCheckOptionUsingPUT,
} from 'types/api/services/person/person';
import { CitizenshipType } from 'types/person';

/** PersonService */
export class PersonService {
  /**
   * @summary Completed the contractor decision tool
   */
  static acceptContractorTool = acceptContractorTool;

  /**
   * Update current profile of logged in candidate
   * @summary Update candidate profile
   */
  static updatePerson = updatePerson;

  /**
   * Add arbitrary key-value pairs against logged in candidate
   * @summary Add additional information against logged in candidate
   */
  static saveAdditionalInformation = saveAdditionalInformation;

  /**
   * Update international bank details of logged in candidate
   * @summary Update candidate international bank details
   */
  static updatePersonBankDetailsInternational =
    updatePersonBankDetailsInternational;

  /**
   * Update local bank details of logged in candidate
   * @summary Update candidate local bank details
   */
  static updatePersonBankDetails = updatePersonBankDetails;

  /**
   * Upload user document for logged in candidate
   * @summary Upload user document for logged in candidate
   */
  static uploadDocument = uploadDocument;

  /**
   * Delete document by document GUID  by logged in candidate
   * @summary Delete user document by document GUID for logged in candidate
   */
  static deleteDocument = deleteDocument;

  /**
   * Retrieve original document provided for logged in candidate to amend and upload
   * @summary Retrieve original document for logged in candidate
   */
  static retrieveOriginalDocument = retrieveOriginalDocument;

  /**
   * Retrieve Signed document provided for logged in candidate to amend and upload
   * @summary Retrieve signed document for logged in candidate
   */
  static retrieveSignedDocument = retrieveSignedDocument;

  /**
   * Retrieve user amended document provided by logged in candidate previously
   * @summary Retrieve user amended document for logged in candidate
   */
  static retrieveUserDocument = retrieveUserDocument;

  /**
   * Update existing user document for logged in candidate
   * @summary Update existing user document for logged in candidate
   */
  static updateDocument = updateDocument;

  /**
   * Add qualification for logged in candidate
   * @summary Add qualification for logged in candidate
   */
  static addQualification = addQualification;

  /**
   * Delete qualification by qualification GUID  by logged in candidate
   * @summary Delete qualification by qualification GUID for logged in candidate
   */
  static deleteQualification = deleteQualification;

  /**
   * Digitally Sign a task
   * @summary (Digital) Sign off a particular Task
   */
  static signTask = signTask;

  static updateIsAustralianCitizen = async (
    isAustralianCitizen: string
  ): Promise<AxiosResponse<PersonModel>> => {
    if (isAustralianCitizen === 'true') {
      return updateIsAustralianCitizenUsingPUT(
        CitizenshipType.AUSTRALIAN_CITIZEN
      );
    } else {
      return updateIsAustralianCitizenUsingPUT(
        CitizenshipType.NON_AUSTRALIAN_CITIZEN
      );
    }
  };

  /**
   * Copy original document to user
   * @summary Copy original document to user
   */
  static copyOriginalDocumentToUserUsingPOST =
    copyOriginalDocumentToUserUsingPOST;

  /**
   * @summary updateIDCheckOption
   */
  static updateIDCheckOptionUsingPUT = updateIDCheckOptionUsingPUT;
}
