import {
  retrieveFundDetails,
  searchFunds,
} from 'types/api/services/fund-validation/fund-validation';

/** FvsService */
export class FvsService {
  /** Search ATO database of Super funds : Search ATO database of Super funds */
  static searchFunds = searchFunds;

  /** Retrieve Super fund details : Retrieve Super fund details*/
  static retrieveFundDetails = retrieveFundDetails;
}
