/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */
import type { TenantModel } from '../..';
import getBranchListMutator from '../../../../utils/HttpClientOrval';
import getCompanyListMutator from '../../../../utils/HttpClientOrval';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * Get the branch list
 * @summary Get Branch List
 */
export const getBranchList = (
  company: string,
  options?: SecondParameter<typeof getBranchListMutator>
) => {
  return getBranchListMutator<TenantModel[]>(
    { url: `/tenant/branch-list/${company}`, method: 'get' },
    options
  );
};
/**
 * Get the Company list
 * @summary Get Company List
 */
export const getCompanyList = (
  options?: SecondParameter<typeof getCompanyListMutator>
) => {
  return getCompanyListMutator<TenantModel[]>(
    { url: `/tenant/company-list`, method: 'get' },
    options
  );
};
