import { Auth } from 'aws-amplify';
import signOut from './signOut';
import { getTenantToken } from './tenantToken';
import clearStorageData from './clearStorageData';
import { Role } from 'types/user';

const headersConfig = (HttpClient, TOKEN_DISABLED) => {
  if (!TOKEN_DISABLED) {
    HttpClient.interceptors.request.use(
      async (config) => {
        try {
          config.headers['Cache-Control'] = 'no-cache';
          config.headers['Pragma'] = 'no-cache';
          const authData = await Auth.currentSession();
          const token = authData.getIdToken();
          if (
            config.url.startsWith('/token/') ||
            config.url.startsWith('/tenant/company-list') ||
            config.url.startsWith('/user/details') ||
            config.url.startsWith('/worker')
          ) {
            config.headers.Authorization = token.jwtToken;
            return config;
          } else if (getTenantToken()) {
            config.headers.Authorization = getTenantToken();
            return config;
          } else {
            config.headers.Authorization = token.jwtToken;
            return config;
          }
        } catch {
          console.log('session is invalid/expired');
          clearStorageData();
          window.location.replace(window.location.origin);
        }
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    HttpClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          signOut();
        }
      }
    );
  } else {
    window.alert(
      'Moving Forward, please set REACT_APP_AUTH_DISABLED=false in .env file'
    );
  }
};

export default headersConfig;
