/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */
import type {
  PersonModel,
  PersonFlowModelArrayBody,
  AddFlowToPersonParams,
  UploadDocumentAdminBody,
  UploadDocumentAdminParams,
  OptionalOfstringBody,
  ApproveDocumentParams,
  ResponseEntity,
  RetrieveOriginalDocumentAdminParams,
  UpdateDocumentAdminBody,
  UpdateDocumentAdminParams,
  RejectDocumentRejectedreasonBody,
  RejectDocumentParams,
  WorkerSearchResponse,
  WorkerSearchRequest,
  TaskConfigModel,
  NascentPersonResponse,
  NascentPersonRequest,
  CreateProfileParams,
  PersonUpdateModelBody,
  UpdateProfileUsingPUTParams,
  RemoveProfileUsingDELETEParams,
  ActivateProfileUsingPOSTParams,
  UpdateIsAdditionalDocumentCompletedUsingPUTParams,
  RateResponseModel,
  RateRequestModel,
  CancelProfileUsingPUTParams,
  ProfileContractRequest,
  CreateOrUpdateProfileWithContractUsingPOSTParams,
  AddWorkOrderNumberUsingPOSTParams,
  DeactivateProfileUsingPOSTParams,
  ApproveDecisionToolParams,
  RejectDecisionToolParams,
  DeleteOriginalDocumentAdminParams,
  ProfileEngagementRequest,
  CreateOrUpdateProfileWithEngagementUsingPOSTParams,
  RemoveFlowFromPersonParams,
  CreateCandidateParams,
  ProfileContractRateRequest,
  CreateOrUpdateProfileWithRateUsingPOSTParams,
  TimesheetRequestModel,
  UpdateTimesheetParams,
  ProfileWorksiteRequest,
  CreateOrUpdateProfileWithWorksiteUsingPOSTParams,
  PersonProfiles,
} from '../..';
import addFlowToPersonMutator from '../../../../utils/HttpClientOrval';
import uploadDocumentAdminMutator from '../../../../utils/HttpClientOrval';
import approveDocumentMutator from '../../../../utils/HttpClientOrval';
import retrieveOriginalDocumentAdminMutator from '../../../../utils/HttpClientOrval';
import updateDocumentAdminMutator from '../../../../utils/HttpClientOrval';
import rejectDocumentMutator from '../../../../utils/HttpClientOrval';
import getWorkerIdMutator from '../../../../utils/HttpClientOrval';
import searchWorkersMutator from '../../../../utils/HttpClientOrval';
import getDefaultTasksMutator from '../../../../utils/HttpClientOrval';
import getProfilesMutator from '../../../../utils/HttpClientOrval';
import createProfileMutator from '../../../../utils/HttpClientOrval';
import getProfileUsingGETMutator from '../../../../utils/HttpClientOrval';
import updateProfileUsingPUTMutator from '../../../../utils/HttpClientOrval';
import removeProfileUsingDELETEMutator from '../../../../utils/HttpClientOrval';
import activateProfileUsingPOSTMutator from '../../../../utils/HttpClientOrval';
import updateIsAdditionalDocumentCompletedUsingPUTMutator from '../../../../utils/HttpClientOrval';
import calculateRateMutator from '../../../../utils/HttpClientOrval';
import cancelProfileUsingPUTMutator from '../../../../utils/HttpClientOrval';
import createOrUpdateProfileWithContractUsingPOSTMutator from '../../../../utils/HttpClientOrval';
import addWorkOrderNumberUsingPOSTMutator from '../../../../utils/HttpClientOrval';
import deactivateProfileUsingPOSTMutator from '../../../../utils/HttpClientOrval';
import approveDecisionToolMutator from '../../../../utils/HttpClientOrval';
import rejectDecisionToolMutator from '../../../../utils/HttpClientOrval';
import deleteOriginalDocumentAdminMutator from '../../../../utils/HttpClientOrval';
import createOrUpdateProfileWithEngagementUsingPOSTMutator from '../../../../utils/HttpClientOrval';
import removeFlowFromPersonMutator from '../../../../utils/HttpClientOrval';
import createCandidateMutator from '../../../../utils/HttpClientOrval';
import createOrUpdateProfileWithRateUsingPOSTMutator from '../../../../utils/HttpClientOrval';
import updateTimesheetMutator from '../../../../utils/HttpClientOrval';
import createOrUpdateProfileWithWorksiteUsingPOSTMutator from '../../../../utils/HttpClientOrval';
import retrieveProfilesUsingGETMutator from '../../../../utils/HttpClientOrval';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * (ADMIN) Add additional flow for candidate to complete
 * @summary Add flow to candidate
 */
export const addFlowToPerson = (
  workerGuid: string,
  profileGuid: string,
  personFlowModelArrayBody: PersonFlowModelArrayBody,
  params?: AddFlowToPersonParams,
  options?: SecondParameter<typeof addFlowToPersonMutator>
) => {
  return addFlowToPersonMutator<PersonModel>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/flow`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: personFlowModelArrayBody,
      params,
    },
    options
  );
};
/**
 * Upload original document for candidate
 * @summary Upload original document for worker profile
 */
export const uploadDocumentAdmin = (
  workerGuid: string,
  profileGuid: string,
  uploadDocumentAdminBody: UploadDocumentAdminBody,
  params: UploadDocumentAdminParams,
  options?: SecondParameter<typeof uploadDocumentAdminMutator>
) => {
  const formData = new FormData();
  formData.append('document', uploadDocumentAdminBody.document);

  return uploadDocumentAdminMutator<PersonModel>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/document/original`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
      params,
    },
    options
  );
};
/**
 * (ADMIN) Approve user uploaded document after reviewing. This is for tasks that require manual document approval to be marked as complete
 * @summary Approve user uploaded document
 */
export const approveDocument = (
  workerGuid: string,
  profileGuid: string,
  docGuid: string,
  optionalOfstringBody: OptionalOfstringBody,
  params?: ApproveDocumentParams,
  options?: SecondParameter<typeof approveDocumentMutator>
) => {
  return approveDocumentMutator<PersonModel>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/document/${docGuid}/approve`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: optionalOfstringBody,
      params,
    },
    options
  );
};
/**
 * Retrieve original document provided to the candidate to amend and upload
 * @summary Retrieve original document
 */
export const retrieveOriginalDocumentAdmin = (
  workerGuid: string,
  profileGuid: string,
  docGuid: string,
  params: RetrieveOriginalDocumentAdminParams,
  options?: SecondParameter<typeof retrieveOriginalDocumentAdminMutator>
) => {
  return retrieveOriginalDocumentAdminMutator<ResponseEntity>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/document/${docGuid}/original`,
      method: 'get',
      params,
    },
    options
  );
};
/**
 * Update existing original document for candidate
 * @summary Update existing original document for candidate
 */
export const updateDocumentAdmin = (
  workerGuid: string,
  profileGuid: string,
  docGuid: string,
  updateDocumentAdminBody: UpdateDocumentAdminBody,
  params?: UpdateDocumentAdminParams,
  options?: SecondParameter<typeof updateDocumentAdminMutator>
) => {
  const formData = new FormData();
  if (updateDocumentAdminBody.document !== undefined) {
    formData.append('document', updateDocumentAdminBody.document);
  }

  return updateDocumentAdminMutator<PersonModel>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/document/${docGuid}/original`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
      params,
    },
    options
  );
};
/**
 * Reject user uploaded document after reviewing with a reason. This is for tasks that require manual document approval to be marked as complete
 * @summary Reject user uploaded document
 */
export const rejectDocument = (
  workerGuid: string,
  profileGuid: string,
  docGuid: string,
  rejectDocumentRejectedreasonBody: RejectDocumentRejectedreasonBody,
  params?: RejectDocumentParams,
  options?: SecondParameter<typeof rejectDocumentMutator>
) => {
  return rejectDocumentMutator<PersonModel>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/document/${docGuid}/reject`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: rejectDocumentRejectedreasonBody,
      params,
    },
    options
  );
};
/**
 * (ADMIN) Get a worker guid
 * @summary Get new worker id for creating workers
 */
export const getWorkerId = (
  options?: SecondParameter<typeof getWorkerIdMutator>
) => {
  return getWorkerIdMutator<string>(
    { url: `/admin/worker/guid`, method: 'get' },
    options
  );
};
/**
 * (ADMIN) Get a worker guid
 * @summary Get workers in the channel BY status
 */
export const searchWorkers = (
  workerSearchRequest: WorkerSearchRequest,
  options?: SecondParameter<typeof searchWorkersMutator>
) => {
  return searchWorkersMutator<WorkerSearchResponse>(
    {
      url: `/admin/worker/search`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: workerSearchRequest,
    },
    options
  );
};
/**
 * (ADMIN) Retrieve the list of default tasks available for the tenant
 * @summary Get Tenant default tasks
 */
export const getDefaultTasks = (
  options?: SecondParameter<typeof getDefaultTasksMutator>
) => {
  return getDefaultTasksMutator<TaskConfigModel[]>(
    { url: `/admin/worker/tasks`, method: 'get' },
    options
  );
};
/**
 * (ADMIN) Get profiles from worker guid
 * @summary Get profiles of specified worker in the current channel
 */
export const getProfiles = (
  workerGuid: string,
  options?: SecondParameter<typeof getProfilesMutator>
) => {
  return getProfilesMutator<PersonModel[]>(
    { url: `/admin/worker/${workerGuid}`, method: 'get' },
    options
  );
};
/**
 * (ADMIN) Creates profile for a candidate
 * @summary Create candidate profile
 */
export const createProfile = (
  workerGuid: string,
  nascentPersonRequest: NascentPersonRequest,
  params?: CreateProfileParams,
  options?: SecondParameter<typeof createProfileMutator>
) => {
  return createProfileMutator<NascentPersonResponse>(
    {
      url: `/admin/worker/${workerGuid}/profile`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: nascentPersonRequest,
      params,
    },
    options
  );
};
/**
 * @summary getProfile
 */
export const getProfileUsingGET = (
  workerGuid: string,
  profileGuid: string,
  options?: SecondParameter<typeof getProfileUsingGETMutator>
) => {
  return getProfileUsingGETMutator<PersonModel>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}`,
      method: 'get',
    },
    options
  );
};
/**
 * @summary updateProfile
 */
export const updateProfileUsingPUT = (
  workerGuid: string,
  profileGuid: string,
  personUpdateModelBody: PersonUpdateModelBody,
  params?: UpdateProfileUsingPUTParams,
  options?: SecondParameter<typeof updateProfileUsingPUTMutator>
) => {
  return updateProfileUsingPUTMutator<PersonModel>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: personUpdateModelBody,
      params,
    },
    options
  );
};
/**
 * @summary removeProfile
 */
export const removeProfileUsingDELETE = (
  workerGuid: string,
  profileGuid: string,
  params?: RemoveProfileUsingDELETEParams,
  options?: SecondParameter<typeof removeProfileUsingDELETEMutator>
) => {
  return removeProfileUsingDELETEMutator<void>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}`,
      method: 'delete',
      params,
    },
    options
  );
};
/**
 * @summary activateProfile
 */
export const activateProfileUsingPOST = (
  workerGuid: string,
  profileGuid: string,
  params?: ActivateProfileUsingPOSTParams,
  options?: SecondParameter<typeof activateProfileUsingPOSTMutator>
) => {
  return activateProfileUsingPOSTMutator<PersonModel>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/activate`,
      method: 'post',
      params,
    },
    options
  );
};
/**
 * @summary updateIsAdditionalDocumentCompleted
 */
export const updateIsAdditionalDocumentCompletedUsingPUT = (
  workerGuid: string,
  profileGuid: string,
  choice: boolean,
  params?: UpdateIsAdditionalDocumentCompletedUsingPUTParams,
  options?: SecondParameter<
    typeof updateIsAdditionalDocumentCompletedUsingPUTMutator
  >
) => {
  return updateIsAdditionalDocumentCompletedUsingPUTMutator<PersonModel>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/additional-document-completed/${choice}`,
      method: 'put',
      params,
    },
    options
  );
};
/**
 * (ADMIN) Calculate the admin and other rates for the candidate
 * @summary Get rates calculated
 */
export const calculateRate = (
  workerGuid: string,
  profileGuid: string,
  rateRequestModel: RateRequestModel,
  options?: SecondParameter<typeof calculateRateMutator>
) => {
  return calculateRateMutator<RateResponseModel>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/calculaterate`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: rateRequestModel,
    },
    options
  );
};
/**
 * @summary cancelProfile
 */
export const cancelProfileUsingPUT = (
  workerGuid: string,
  profileGuid: string,
  cancelProfileUsingPUTBody: string,
  params?: CancelProfileUsingPUTParams,
  options?: SecondParameter<typeof cancelProfileUsingPUTMutator>
) => {
  return cancelProfileUsingPUTMutator<void>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/cancel`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: cancelProfileUsingPUTBody,
      params,
    },
    options
  );
};
/**
 * @summary createOrUpdateProfileWithContract
 */
export const createOrUpdateProfileWithContractUsingPOST = (
  workerGuid: string,
  profileGuid: string,
  profileContractRequest: ProfileContractRequest,
  params?: CreateOrUpdateProfileWithContractUsingPOSTParams,
  options?: SecondParameter<
    typeof createOrUpdateProfileWithContractUsingPOSTMutator
  >
) => {
  return createOrUpdateProfileWithContractUsingPOSTMutator<PersonModel>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/contract`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: profileContractRequest,
      params,
    },
    options
  );
};
/**
 * @summary addWorkOrderNumber
 */
export const addWorkOrderNumberUsingPOST = (
  workerGuid: string,
  profileGuid: string,
  contractType: 'INITIAL' | 'EXTENSION' | 'AMENDMENT',
  workOrderNumber: string,
  params?: AddWorkOrderNumberUsingPOSTParams,
  options?: SecondParameter<typeof addWorkOrderNumberUsingPOSTMutator>
) => {
  return addWorkOrderNumberUsingPOSTMutator<PersonModel>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/contract/${contractType}/wo/${workOrderNumber}`,
      method: 'post',
      params,
    },
    options
  );
};
/**
 * @summary deactivateProfile
 */
export const deactivateProfileUsingPOST = (
  workerGuid: string,
  profileGuid: string,
  params?: DeactivateProfileUsingPOSTParams,
  options?: SecondParameter<typeof deactivateProfileUsingPOSTMutator>
) => {
  return deactivateProfileUsingPOSTMutator<PersonModel>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/deactivate`,
      method: 'post',
      params,
    },
    options
  );
};
/**
 * (ADMIN) Calculate the admin and other rates for the candidate
 * @summary Get rates calculated
 */
export const approveDecisionTool = (
  workerGuid: string,
  profileGuid: string,
  params?: ApproveDecisionToolParams,
  options?: SecondParameter<typeof approveDecisionToolMutator>
) => {
  return approveDecisionToolMutator<PersonModel>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/decisiontool/approve`,
      method: 'post',
      params,
    },
    options
  );
};
/**
 * (ADMIN) Calculate the admin and other rates for the candidate
 * @summary Get rates calculated
 */
export const rejectDecisionTool = (
  workerGuid: string,
  profileGuid: string,
  params?: RejectDecisionToolParams,
  options?: SecondParameter<typeof rejectDecisionToolMutator>
) => {
  return rejectDecisionToolMutator<PersonModel>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/decisiontool/reject`,
      method: 'post',
      params,
    },
    options
  );
};
/**
 * Delete original document
 * @summary Delete original document
 */
export const deleteOriginalDocumentAdmin = (
  workerGuid: string,
  profileGuid: string,
  docGuid: string,
  params?: DeleteOriginalDocumentAdminParams,
  options?: SecondParameter<typeof deleteOriginalDocumentAdminMutator>
) => {
  return deleteOriginalDocumentAdminMutator<ResponseEntity>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/document/${docGuid}/original`,
      method: 'delete',
      params,
    },
    options
  );
};
/**
 * @summary createOrUpdateProfileWithEngagement
 */
export const createOrUpdateProfileWithEngagementUsingPOST = (
  workerGuid: string,
  profileGuid: string,
  profileEngagementRequest: ProfileEngagementRequest,
  params?: CreateOrUpdateProfileWithEngagementUsingPOSTParams,
  options?: SecondParameter<
    typeof createOrUpdateProfileWithEngagementUsingPOSTMutator
  >
) => {
  return createOrUpdateProfileWithEngagementUsingPOSTMutator<PersonModel>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/engagement`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: profileEngagementRequest,
      params,
    },
    options
  );
};
/**
 * (ADMIN) Delete existing flow from candidate
 * @summary Delete flow from candidate
 */
export const removeFlowFromPerson = (
  workerGuid: string,
  profileGuid: string,
  params: RemoveFlowFromPersonParams,
  options?: SecondParameter<typeof removeFlowFromPersonMutator>
) => {
  return removeFlowFromPersonMutator<PersonModel>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/flow`,
      method: 'delete',
      params,
    },
    options
  );
};
/**
 * Create the cadidate on the vendor payroll system
 * @summary Create candidate on payroll system
 */
export const createCandidate = (
  workerGuid: string,
  profileGuid: string,
  params?: CreateCandidateParams,
  options?: SecondParameter<typeof createCandidateMutator>
) => {
  return createCandidateMutator<PersonModel>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/push`,
      method: 'post',
      params,
    },
    options
  );
};
/**
 * @summary createOrUpdateProfileWithRate
 */
export const createOrUpdateProfileWithRateUsingPOST = (
  workerGuid: string,
  profileGuid: string,
  profileContractRateRequest: ProfileContractRateRequest,
  params?: CreateOrUpdateProfileWithRateUsingPOSTParams,
  options?: SecondParameter<
    typeof createOrUpdateProfileWithRateUsingPOSTMutator
  >
) => {
  return createOrUpdateProfileWithRateUsingPOSTMutator<PersonModel>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/rate`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: profileContractRateRequest,
      params,
    },
    options
  );
};
/**
 * (ADMIN) Update the timesheet information for the candidate
 * @summary Update Timesheet
 */
export const updateTimesheet = (
  workerGuid: string,
  profileGuid: string,
  timesheetRequestModel: TimesheetRequestModel,
  params?: UpdateTimesheetParams,
  options?: SecondParameter<typeof updateTimesheetMutator>
) => {
  return updateTimesheetMutator<PersonModel>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/timesheet`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: timesheetRequestModel,
      params,
    },
    options
  );
};
/**
 * @summary createOrUpdateProfileWithWorksite
 */
export const createOrUpdateProfileWithWorksiteUsingPOST = (
  workerGuid: string,
  profileGuid: string,
  profileWorksiteRequest: ProfileWorksiteRequest,
  params?: CreateOrUpdateProfileWithWorksiteUsingPOSTParams,
  options?: SecondParameter<
    typeof createOrUpdateProfileWithWorksiteUsingPOSTMutator
  >
) => {
  return createOrUpdateProfileWithWorksiteUsingPOSTMutator<PersonModel>(
    {
      url: `/admin/worker/${workerGuid}/profile/${profileGuid}/worksite`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: profileWorksiteRequest,
      params,
    },
    options
  );
};
/**
 * @summary retrieveProfiles
 */
export const retrieveProfilesUsingGET = (
  workerGuid: string,
  options?: SecondParameter<typeof retrieveProfilesUsingGETMutator>
) => {
  return retrieveProfilesUsingGETMutator<PersonProfiles>(
    { url: `/admin/worker/${workerGuid}/profiles`, method: 'get' },
    options
  );
};
