/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */
import type {
  AbnDetailsResponse,
  LookupAbnParams,
  AbnreentResponceDetails,
  GetAbnQuestionnaireParams,
  AbnRegistrationDetailsResponse,
  AbnRegRequest,
  SearchAbnParams,
} from '../..';
import lookupAbnMutator from '../../../../utils/HttpClientOrval';
import getAbnQuestionnaireMutator from '../../../../utils/HttpClientOrval';
import registerAbnMutator from '../../../../utils/HttpClientOrval';
import searchAbnMutator from '../../../../utils/HttpClientOrval';
import sayHelloUsingGETMutator from '../../../../utils/HttpClientOrval';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * Search ABR for details of ABN
 * @summary Lookup ABR for ABN
 */
export const lookupAbn = (
  params: LookupAbnParams,
  options?: SecondParameter<typeof lookupAbnMutator>
) => {
  return lookupAbnMutator<AbnDetailsResponse>(
    { url: `/abn/lookup`, method: 'get', params },
    options
  );
};
/**
 * Retrieve the Australian Business Number Registration Get ABN Entitlement Questionnaire
 * @summary Retrieve ABN Registration Questionnaire
 */
export const getAbnQuestionnaire = (
  params: GetAbnQuestionnaireParams,
  options?: SecondParameter<typeof getAbnQuestionnaireMutator>
) => {
  return getAbnQuestionnaireMutator<AbnreentResponceDetails>(
    { url: `/abn/questionnaire`, method: 'get', params },
    options
  );
};
/**
 * Submit business registration application to ABR
 * @summary Register business for ABN
 */
export const registerAbn = (
  abnRegRequest: AbnRegRequest,
  options?: SecondParameter<typeof registerAbnMutator>
) => {
  return registerAbnMutator<AbnRegistrationDetailsResponse>(
    {
      url: `/abn/register`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: abnRegRequest,
    },
    options
  );
};
/**
 * Search ABR for details of ABN and validate response
 * @summary Search ABR for ABN
 */
export const searchAbn = (
  params: SearchAbnParams,
  options?: SecondParameter<typeof searchAbnMutator>
) => {
  return searchAbnMutator<AbnDetailsResponse>(
    { url: `/abn/search`, method: 'get', params },
    options
  );
};
/**
 * @summary sayHello
 */
export const sayHelloUsingGET = (
  options?: SecondParameter<typeof sayHelloUsingGETMutator>
) => {
  return sayHelloUsingGETMutator<string>(
    { url: `/noauth/hello`, method: 'get' },
    options
  );
};
