import useTheme from 'hooks/useTheme';
import { Helmet } from 'react-helmet';

const Theme = () => {
  const { currentTheme } = useTheme();

  return (
    <>
      {currentTheme && (
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            href={`${process.env.REACT_APP_BASE_URL}/themes/${currentTheme}/theme.css`}
          />
          <link
            rel="icon"
            type="image/png"
            href={`${process.env.REACT_APP_BASE_URL}/themes/favicon/${currentTheme}-favicon-32x32.png`}
          />
        </Helmet>
      )}
    </>
  );
};

export default Theme;
