/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */
import type {
  BusinessDetailsModel,
  ResponseEntity,
  UpdateBusinessDetailsParams,
} from '../..';
import retrieveBusinessDetailsMutator from '../../../../utils/HttpClientOrval';
import updateBusinessDetailsMutator from '../../../../utils/HttpClientOrval';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * Retrieve saved business details for company of logged in candidate
 * @summary Retrieve saved business details
 */
export const retrieveBusinessDetails = (
  options?: SecondParameter<typeof retrieveBusinessDetailsMutator>
) => {
  return retrieveBusinessDetailsMutator<BusinessDetailsModel>(
    { url: `/business-details`, method: 'get' },
    options
  );
};
/**
 * Update business details for company of logged in candidate
 * @summary Update business details
 */
export const updateBusinessDetails = (
  businessDetailsModel: BusinessDetailsModel,
  params?: UpdateBusinessDetailsParams,
  options?: SecondParameter<typeof updateBusinessDetailsMutator>
) => {
  return updateBusinessDetailsMutator<ResponseEntity>(
    {
      url: `/business-details`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: businessDetailsModel,
      params,
    },
    options
  );
};
