import { AxiosResponse } from 'axios';

import { PersonModel, Document } from 'types/api';
import {
  deleteDocumentAdmin,
  deleteMultiplePerson,
  deletePerson,
  getPayrollInfo,
  pushPersonData,
  pushPersonDataManual,
  reRegisterPersonByGuid,
  retrieveAllPerson,
  retrievePersonByGuid,
  retrieveProfilePictureAdmin,
  retrieveSignedOriginalDocumentAdmin,
  retrieveUserDocumentAdmin,
  saveAdditionalInformationAdmin,
  updatePayrollInfo,
  updatePersonByGUID,
  uploadProfilePictureAdmin,
  isExistingEmail,
} from 'types/api/services/person-admin/person-admin';
import HttpClient from 'utils/HttpClient';

/** PersonAdminService */
export class PersonAdminService {
  /**
   * (ADMIN) Retrieves all candidate profiles by list of statues
   * @summary Retrieve all candidate profiles by list of statues
   */
  static retrieveAllPerson = retrieveAllPerson;

  /**
   * (ADMIN) Delete multiple candidate profiles by GUID
   * @summary Delete multiple candidate profiles by GUID
   */
  static deleteMultiplePerson = deleteMultiplePerson;

  /**
   * (ADMIN) Retrieves candidate profile by GUID
   * @summary Retrieve candidate profile by GUID
   */
  static retrievePerson = retrievePersonByGuid;

  /**
   * (ADMIN) Update candidate profile by GUID
   * @summary Update candidate profile by GUID
   */
  static updatePerson = updatePersonByGUID;

  /**
   * (ADMIN) Delete candidate profile by GUID
   * @summary Delete candidate profile by GUID
   */
  static deletePerson = deletePerson;

  /**
   * (ADMIN) Add arbitrary key-value pairs against a candidate
   * @summary Add additional information against a candidate
   */
  static saveAdditionalInformationAdmin = saveAdditionalInformationAdmin;

  /**
   * (ADMIN) Delete document by document GUID
   * @summary Delete user document by document GUID
   */
  static deleteDocumentAdmin = deleteDocumentAdmin;

  /**
   * Retrieve signed original document provided to the candidate to amend and upload
   * @summary Retrieve signed original document
   */
  static retrieveSignedOriginalDocumentAdmin =
    retrieveSignedOriginalDocumentAdmin;

  /**
   * Retrieve user amended document provided by the candidate previously
   * @summary Retrieve user amended document
   */
  static retrieveUserDocumentAdmin = retrieveUserDocumentAdmin;

  /**
   * (ADMIN) Get candidate's payroll additional info
   * @summary Get candidate's payroll additional info
   */
  static getPayrollInfo = getPayrollInfo;

  /**
   * (ADMIN) Update candidate's payroll additional info
   * @summary Update candidate's payroll additional info
   */
  static updatePayrollInfo = updatePayrollInfo;

  /**
   * (ADMIN) Retrieve profile picture of candidate
   * @summary Retrieve profile picture of candidate
   */
  static retrieveProfilePictureAdmin = retrieveProfilePictureAdmin;

  /**
   * (ADMIN) Upload profile picture of candidate
   * @summary Upload profile picture of candidate
   */
  static uploadProfilePictureAdmin = uploadProfilePictureAdmin;

  /**
   * (ADMIN) Trigger push of stored candidate data to complete onboarding of candidate
   * @summary Trigger push of candidate data
   */
  static pushPersonData = pushPersonData;

  /**
   * (ADMIN) Manually push custom candidate data to complete onboarding of candidate
   * @summary Manually push custom candidate data
   */
  static pushPersonDataManual = pushPersonDataManual;

  /**
   * (ADMIN) Re-register candidate in AWS by GUID
   * @summary Re-register candidate in AWS by GUID
   */
  static reRegister = reRegisterPersonByGuid;

  /** Upload original document for candidate : Upload original document for candidate*/
  static saveDocument = async (
    guid: string,
    request: Document
  ): Promise<AxiosResponse<PersonModel>> => {
    return HttpClient.post(`/admin/person/${guid}/document/original`, request);
  };

  /** Update existing original document for candidate */
  static updateDocument = async (
    guid: string,
    docGuid: string,
    request: Document
  ): Promise<AxiosResponse<PersonModel>> => {
    return HttpClient.post(
      `/admin/person/${guid}/document/${docGuid}/original`,
      request
    );
  };

  static isExistingEmail = isExistingEmail;
}
