/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */
import type {
  SubmitTfndResponse,
  SubmitTfndRequestBody,
  SubmitTfndParams,
  ValidateTfnResponse,
  ValidateTfnRequestDomainBody,
  ValidateTfnParams,
} from '../..';
import submitTfndMutator from '../../../../utils/HttpClientOrval';
import validateTfnMutator from '../../../../utils/HttpClientOrval';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * Submit TFN Declaration directly to ATO for logged in candidate
 * @summary Submit TFN Declaration for logged in candidate
 */
export const submitTfnd = (
  submitTfndRequestBody: SubmitTfndRequestBody,
  params?: SubmitTfndParams,
  options?: SecondParameter<typeof submitTfndMutator>
) => {
  return submitTfndMutator<SubmitTfndResponse>(
    {
      url: `/tfn/declaration`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: submitTfndRequestBody,
      params,
    },
    options
  );
};
/**
 * Validate TFN of logged in candidate using ATO EmployerTICK service
 * @summary Validate TFN of logged in candidate
 */
export const validateTfn = (
  validateTfnRequestDomainBody: ValidateTfnRequestDomainBody,
  params?: ValidateTfnParams,
  options?: SecondParameter<typeof validateTfnMutator>
) => {
  return validateTfnMutator<ValidateTfnResponse>(
    {
      url: `/tfn/validate`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: validateTfnRequestDomainBody,
      params,
    },
    options
  );
};
