/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios from 'axios';

import { API_HOST, TOKEN_DISABLED } from './configs';
import headerConfig from './headerConfig';
const userIp = localStorage.getItem('userIp');
const HttpClient = axios.create({
  baseURL: API_HOST,
  headers: process.env.REACT_APP_AUTH_DISABLED_HEADER
    ? JSON.parse(process.env.REACT_APP_AUTH_DISABLED_HEADER)
    : undefined,
  params: {
    userIp: userIp,
  },
});

headerConfig(HttpClient, TOKEN_DISABLED);

export default HttpClient;
