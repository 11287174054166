import {
  validateWorkingWithChildren,
  validStates,
} from 'types/api/services/working-with-children/working-with-children';

/** WorkingWithChildrenService */
export class WorkingWithChildrenService {
  /** Validate Working with Children check for logged in candidate : Check validity of Working with Children check for logged in candidate */
  static validate = validateWorkingWithChildren;

  /** Retrieve list of valid States : Retrieve list of valid States */
  static validStates = validStates;
}
