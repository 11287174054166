import {
  getWorkerProfile,
  retrieveProfilePicture,
  uploadProfilePicture,
  getWorkerProfiles,
  getAllWorkerProfiles,
} from 'types/api/services/worker/worker';

/** WorkerService */
export class WorkerService {
  /**
   * Get worker profile from worker guid
   * @summary Get worker profile
   */
  static getWorkerProfile = getWorkerProfile;

  /**
   * Retrieve profile picture of logged in candidate
   * @summary Retrieve profile picture of logged in candidate
   */
  static retrieveProfilePicture = retrieveProfilePicture;

  /**
   * Upload profile picture for logged in candidate
   * @summary Upload profile picture for logged in candidate
   */
  static uploadProfilePicture = uploadProfilePicture;

  /**
   * Get profiles for logged in candidate
   * @summary Get profiles for logged in candidate
   */
  static getWorkerProfiles = getWorkerProfiles;

  /**
   * Get all profiles for logged in candidate
   * @summary Get all profiles for logged in candidate
   */
  static getAllWorkerProfiles = getAllWorkerProfiles;
}
