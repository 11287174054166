/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */
import type { ConfigModel } from '../..';
import getConfigMutator from '../../../../utils/HttpClientOrval';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * Get Config
 * @summary Get Config
 */
export const getConfig = (
  options?: SecondParameter<typeof getConfigMutator>
) => {
  return getConfigMutator<ConfigModel>(
    { url: `/config`, method: 'get' },
    options
  );
};
