/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */
import type { FlowModel, FlowModelBody } from '../..';
import getFlowsMutator from '../../../../utils/HttpClientOrval';
import addFlowMutator from '../../../../utils/HttpClientOrval';
import getFlowsForProfileMutator from '../../../../utils/HttpClientOrval';
import getFlowByIdMutator from '../../../../utils/HttpClientOrval';
import updateFlowMutator from '../../../../utils/HttpClientOrval';
import deleteFlowMutator from '../../../../utils/HttpClientOrval';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * (ADMIN) Retrieve the list of flows available for assigning to candidates
 * @summary Get flows
 */
export const getFlows = (options?: SecondParameter<typeof getFlowsMutator>) => {
  return getFlowsMutator<FlowModel[]>({ url: `/flow`, method: 'get' }, options);
};
/**
 * (ADMIN) Create a new flow consisting of tasks required to be completed by candidates
 * @summary Create new flow
 */
export const addFlow = (
  flowModelBody: FlowModelBody,
  options?: SecondParameter<typeof addFlowMutator>
) => {
  return addFlowMutator<string>(
    {
      url: `/flow`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: flowModelBody,
    },
    options
  );
};
/**
 * (ADMIN) Retrieve the list of flows available for assigning to candidates using profile
 * @summary Get flows for profile
 */
export const getFlowsForProfile = (
  profileGuid: string,
  options?: SecondParameter<typeof getFlowsForProfileMutator>
) => {
  return getFlowsForProfileMutator<FlowModel[]>(
    { url: `/flow/profile/${profileGuid}`, method: 'get' },
    options
  );
};
/**
 * (ADMIN) Retrieve an individual flow by ID
 * @summary Get flow by ID
 */
export const getFlowById = (
  id: string,
  options?: SecondParameter<typeof getFlowByIdMutator>
) => {
  return getFlowByIdMutator<FlowModel>(
    { url: `/flow/${id}`, method: 'get' },
    options
  );
};
/**
 * (ADMIN) Update an existing flow
 * @summary Update existing flow
 */
export const updateFlow = (
  id: string,
  flowModelBody: FlowModelBody,
  options?: SecondParameter<typeof updateFlowMutator>
) => {
  return updateFlowMutator<void>(
    {
      url: `/flow/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: flowModelBody,
    },
    options
  );
};
/**
 * (ADMIN) Delete an existing flow
 * @summary Delete existing flow
 */
export const deleteFlow = (
  id: string,
  options?: SecondParameter<typeof deleteFlowMutator>
) => {
  return deleteFlowMutator<void>(
    { url: `/flow/${id}`, method: 'delete' },
    options
  );
};
