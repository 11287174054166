/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */

export type AHPRAInformationModelAhpraTaskStatus =
  (typeof AHPRAInformationModelAhpraTaskStatus)[keyof typeof AHPRAInformationModelAhpraTaskStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AHPRAInformationModelAhpraTaskStatus = {
  SUBMITTED: 'SUBMITTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
} as const;
