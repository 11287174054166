/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */
import type {
  PersonModel,
  PersonLocalBankDetailsModelBody,
  UpdatePersonBankDetailsParams,
  PersonUpdateModelBody,
  UpdatePersonParams,
  AdditionalInformationModelArrayBody,
  SaveAdditionalInformationParams,
  PersonInternationalBankDetailsModel,
  UpdatePersonBankDetailsInternationalParams,
  UpdateIsAustralianCitizenUsingPUTParams,
  AcceptContractorToolParams,
  UploadDocumentAdminBody,
  UploadDocumentParams,
  DeleteDocumentParams,
  CopyOriginalDocumentToUserUsingPOSTParams,
  ResponseEntity,
  RetrieveOriginalDocumentParams,
  RetrieveSignedDocumentParams,
  RetrieveUserDocumentParams,
  UpdateDocumentAdminBody,
  UpdateDocumentParams,
  UpdateIDCheckOptionUsingPUTParams,
  QualificationRequest,
  AddQualificationParams,
  DeleteQualificationParams,
  SignTaskParams,
} from '../..';
import updatePersonBankDetailsMutator from '../../../../utils/HttpClientOrval';
import updatePersonMutator from '../../../../utils/HttpClientOrval';
import saveAdditionalInformationMutator from '../../../../utils/HttpClientOrval';
import updatePersonBankDetailsInternationalMutator from '../../../../utils/HttpClientOrval';
import updateIsAustralianCitizenUsingPUTMutator from '../../../../utils/HttpClientOrval';
import acceptContractorToolMutator from '../../../../utils/HttpClientOrval';
import uploadDocumentMutator from '../../../../utils/HttpClientOrval';
import deleteDocumentMutator from '../../../../utils/HttpClientOrval';
import copyOriginalDocumentToUserUsingPOSTMutator from '../../../../utils/HttpClientOrval';
import retrieveOriginalDocumentMutator from '../../../../utils/HttpClientOrval';
import retrieveSignedDocumentMutator from '../../../../utils/HttpClientOrval';
import retrieveUserDocumentMutator from '../../../../utils/HttpClientOrval';
import updateDocumentMutator from '../../../../utils/HttpClientOrval';
import updateIDCheckOptionUsingPUTMutator from '../../../../utils/HttpClientOrval';
import addQualificationMutator from '../../../../utils/HttpClientOrval';
import deleteQualificationMutator from '../../../../utils/HttpClientOrval';
import signTaskMutator from '../../../../utils/HttpClientOrval';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * Update local bank details of logged in candidate
 * @summary Update candidate local bank details
 */
export const updatePersonBankDetails = (
  personLocalBankDetailsModelBody: PersonLocalBankDetailsModelBody,
  params?: UpdatePersonBankDetailsParams,
  options?: SecondParameter<typeof updatePersonBankDetailsMutator>
) => {
  return updatePersonBankDetailsMutator<PersonModel>(
    {
      url: `/person/bank-details/local`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: personLocalBankDetailsModelBody,
      params,
    },
    options
  );
};
/**
 * Update current profile of logged in candidate
 * @summary Update candidate profile
 */
export const updatePerson = (
  personUpdateModelBody: PersonUpdateModelBody,
  params?: UpdatePersonParams,
  options?: SecondParameter<typeof updatePersonMutator>
) => {
  return updatePersonMutator<PersonModel>(
    {
      url: `/person`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: personUpdateModelBody,
      params,
    },
    options
  );
};
/**
 * Add arbitrary key-value pairs against logged in candidate
 * @summary Add additional information against logged in candidate
 */
export const saveAdditionalInformation = (
  additionalInformationModelArrayBody: AdditionalInformationModelArrayBody,
  params?: SaveAdditionalInformationParams,
  options?: SecondParameter<typeof saveAdditionalInformationMutator>
) => {
  return saveAdditionalInformationMutator<PersonModel>(
    {
      url: `/person/additional-information`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: additionalInformationModelArrayBody,
      params,
    },
    options
  );
};
/**
 * Update international bank details of logged in candidate
 * @summary Update candidate international bank details
 */
export const updatePersonBankDetailsInternational = (
  personInternationalBankDetailsModel: PersonInternationalBankDetailsModel,
  params?: UpdatePersonBankDetailsInternationalParams,
  options?: SecondParameter<typeof updatePersonBankDetailsInternationalMutator>
) => {
  return updatePersonBankDetailsInternationalMutator<PersonModel>(
    {
      url: `/person/bank-details/international`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: personInternationalBankDetailsModel,
      params,
    },
    options
  );
};
/**
 * @summary updateIsAustralianCitizen
 */
export const updateIsAustralianCitizenUsingPUT = (
  citizenshipType:
    | 'AUSTRALIAN_CITIZEN'
    | 'NON_AUSTRALIAN_CITIZEN'
    | 'PRE_EXISTING_USER'
    | 'NOT_PROVIDED',
  params?: UpdateIsAustralianCitizenUsingPUTParams,
  options?: SecondParameter<typeof updateIsAustralianCitizenUsingPUTMutator>
) => {
  return updateIsAustralianCitizenUsingPUTMutator<PersonModel>(
    {
      url: `/person/citizenship-type/${citizenshipType}`,
      method: 'put',
      params,
    },
    options
  );
};
/**
 * @summary Completed the contractor decision tool
 */
export const acceptContractorTool = (
  params?: AcceptContractorToolParams,
  options?: SecondParameter<typeof acceptContractorToolMutator>
) => {
  return acceptContractorToolMutator<PersonModel>(
    { url: `/person/contract/decisiontool`, method: 'post', params },
    options
  );
};
/**
 * Upload user document for logged in candidate
 * @summary Upload user document for logged in candidate
 */
export const uploadDocument = (
  uploadDocumentAdminBody: UploadDocumentAdminBody,
  params: UploadDocumentParams,
  options?: SecondParameter<typeof uploadDocumentMutator>
) => {
  const formData = new FormData();
  formData.append('document', uploadDocumentAdminBody.document);

  return uploadDocumentMutator<PersonModel>(
    {
      url: `/person/document/user`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
      params,
    },
    options
  );
};
/**
 * Delete document by document GUID  by logged in candidate
 * @summary Delete user document by document GUID for logged in candidate
 */
export const deleteDocument = (
  docGuid: string,
  params?: DeleteDocumentParams,
  options?: SecondParameter<typeof deleteDocumentMutator>
) => {
  return deleteDocumentMutator<PersonModel>(
    { url: `/person/document/${docGuid}`, method: 'delete', params },
    options
  );
};
/**
 * @summary copyOriginalDocumentToUser
 */
export const copyOriginalDocumentToUserUsingPOST = (
  docGuid: string,
  params?: CopyOriginalDocumentToUserUsingPOSTParams,
  options?: SecondParameter<typeof copyOriginalDocumentToUserUsingPOSTMutator>
) => {
  return copyOriginalDocumentToUserUsingPOSTMutator<void>(
    {
      url: `/person/document/${docGuid}/copy-original-to-user`,
      method: 'post',
      params,
    },
    options
  );
};
/**
 * Retrieve original document provided for logged in candidate to amend and upload
 * @summary Retrieve original document for logged in candidate
 */
export const retrieveOriginalDocument = (
  docGuid: string,
  params: RetrieveOriginalDocumentParams,
  options?: SecondParameter<typeof retrieveOriginalDocumentMutator>
) => {
  return retrieveOriginalDocumentMutator<ResponseEntity>(
    { url: `/person/document/${docGuid}/original`, method: 'get', params },
    options
  );
};
/**
 * Retrieve Signed document provided for logged in candidate to amend and upload
 * @summary Retrieve signed document for logged in candidate
 */
export const retrieveSignedDocument = (
  docGuid: string,
  params: RetrieveSignedDocumentParams,
  options?: SecondParameter<typeof retrieveSignedDocumentMutator>
) => {
  return retrieveSignedDocumentMutator<ResponseEntity>(
    { url: `/person/document/${docGuid}/signed`, method: 'get', params },
    options
  );
};
/**
 * Retrieve user amended document provided by logged in candidate previously
 * @summary Retrieve user amended document for logged in candidate
 */
export const retrieveUserDocument = (
  docGuid: string,
  params: RetrieveUserDocumentParams,
  options?: SecondParameter<typeof retrieveUserDocumentMutator>
) => {
  return retrieveUserDocumentMutator<ResponseEntity>(
    { url: `/person/document/${docGuid}/user`, method: 'get', params },
    options
  );
};
/**
 * Update existing user document for logged in candidate
 * @summary Update existing user document for logged in candidate
 */
export const updateDocument = (
  docGuid: string,
  updateDocumentAdminBody: UpdateDocumentAdminBody,
  params?: UpdateDocumentParams,
  options?: SecondParameter<typeof updateDocumentMutator>
) => {
  const formData = new FormData();
  if (updateDocumentAdminBody.document !== undefined) {
    formData.append('document', updateDocumentAdminBody.document);
  }

  return updateDocumentMutator<PersonModel>(
    {
      url: `/person/document/${docGuid}/user`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
      params,
    },
    options
  );
};
/**
 * @summary updateIDCheckOption
 */
export const updateIDCheckOptionUsingPUT = (
  idCheckOption: 'PRIMARY_DOCUMENTS' | 'SECONDARY_DOCUMENTS',
  params?: UpdateIDCheckOptionUsingPUTParams,
  options?: SecondParameter<typeof updateIDCheckOptionUsingPUTMutator>
) => {
  return updateIDCheckOptionUsingPUTMutator<PersonModel>(
    { url: `/person/id-check-option/${idCheckOption}`, method: 'put', params },
    options
  );
};
/**
 * Add qualification for logged in candidate
 * @summary Add qualification for logged in candidate
 */
export const addQualification = (
  qualificationRequest: QualificationRequest,
  params?: AddQualificationParams,
  options?: SecondParameter<typeof addQualificationMutator>
) => {
  return addQualificationMutator<PersonModel>(
    {
      url: `/person/qualification`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: qualificationRequest,
      params,
    },
    options
  );
};
/**
 * Delete qualification by qualification GUID  by logged in candidate
 * @summary Delete qualification by qualification GUID for logged in candidate
 */
export const deleteQualification = (
  qualificationGuid: string,
  params?: DeleteQualificationParams,
  options?: SecondParameter<typeof deleteQualificationMutator>
) => {
  return deleteQualificationMutator<PersonModel>(
    {
      url: `/person/qualification/${qualificationGuid}`,
      method: 'delete',
      params,
    },
    options
  );
};
/**
 * Digitally Sign a task
 * @summary (Digital) Sign off a particular Task
 */
export const signTask = (
  params: SignTaskParams,
  options?: SecondParameter<typeof signTaskMutator>
) => {
  return signTaskMutator<PersonModel>(
    { url: `/person/task/sign`, method: 'post', params },
    options
  );
};
