import './splunk-instrumentation';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import AppWithAuth from './AppWithAuth';
import * as serviceWorker from './serviceWorker';
import 'focus-visible';

if (process.env.NODE_ENV === 'test') {
  const { worker } = require('./mocks/browser');
  worker.start();
}
console.log('Before render ...');
ReactDOM.render(<AppWithAuth />, document.getElementById('root'));

serviceWorker.unregister();
