import _ from 'lodash';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  CompanyOption,
  mapTenantToOption,
} from 'components/AdminHeader/CompaniesSelectMenu';
import Loading from 'components/Loading/Loading';
import { selectedCompanySessionKey } from 'constants/sessionStorageKeys';
import { CUSTOM_ROLE } from 'constants/tokenField';
import useLocalStorage from 'hooks/useLocalStorage';
import { useSessionStorage } from 'hooks/useSessionStorage';
import { TenantService, TokenService } from 'services';
import { TenantModel } from 'types/api';
import { setTenantToken } from 'utils/tenantToken';

interface HierarchyContextValues {
  companyList: TenantModel[];
  selectedCompany?: CompanyOption;
  updateSelectedCompany: (option: CompanyOption) => void;
}

interface HierarchyProviderProps {
  useRoles: string[];
  children: ReactNode;
}

const HierarchyContext = createContext({} as HierarchyContextValues);

export const HierarchyProvider = ({
  useRoles,
  children,
}: HierarchyProviderProps) => {
  const [companyList, setCompanyList] = useState<TenantModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userRole] = useLocalStorage('userRole', '');

  const [selectedCompany, setSelectedCompany] = useSessionStorage(
    selectedCompanySessionKey,
    {} as CompanyOption
  );
  //TODO will revisit this when we consider company level login
  // const isInCompanyList = (selectedCompany: CompanyOption) => {
  //   companyList.forEach((company) => {
  //     if (company.channel === selectedCompany.value) {
  //       return true;
  //     }
  //     console.log('in company', _.isEmpty(selectedCompany));
  //   });
  //   console.log('not in company', _.isEmpty(selectedCompany));
  //   return false;
  // };

  const getCompanyList = useCallback(async () => {
    if (userRole !== CUSTOM_ROLE.EMPLOYEE) {
      setIsLoading(true);
      try {
        const { data } = await TenantService.getCompanyList();
        setCompanyList(data);
        if (data.length === 0) {
          setSelectedCompany({});
        } else if (_.isEmpty(selectedCompany)) {
          setSelectedCompany(mapTenantToOption(data[0]));

          await getTenantToken(data[0].channel || '');
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      setCompanyList([]);
    }
  }, []);

  const getTenantToken = async (selectedTenantChannel: string) => {
    try {
      if (selectedTenantChannel !== '') {
        const { data } = await TokenService.getTenantToken(
          selectedTenantChannel,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          useRoles
        );
        setTenantToken(data);
      } else {
        setTenantToken('');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateSelectedCompany = useCallback(async (option: CompanyOption) => {
    setIsLoading(true);
    setSelectedCompany(option);
    await getTenantToken(option.value);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getCompanyList();
  }, [getCompanyList]);

  const values = useMemo(
    () => ({
      companyList,
      selectedCompany,
      updateSelectedCompany,
    }),
    [companyList, selectedCompany, updateSelectedCompany]
  );

  return (
    <HierarchyContext.Provider value={values}>
      {isLoading ? (
        <div className="vh-100 vw-100 d-flex justify-content-center align-items-center">
          <Loading />
        </div>
      ) : (
        children
      )}
    </HierarchyContext.Provider>
  );
};

export const useHierarchy = () => useContext(HierarchyContext);
