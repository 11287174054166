/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */
import type {
  PersonModel,
  RetrieveAllPersonParams,
  DeleteMultiplePersonParams,
  EmailValidationResponse,
  EmailValidationRequest,
  ValidationResponse,
  PersonUpdateModelBody,
  UpdatePersonByGUIDParams,
  AdditionalInformationModelArrayBody,
  SaveAdditionalInformationAdminParams,
  DeleteDocumentAdminParams,
  ResponseEntity,
  RetrieveSignedOriginalDocumentAdminParams,
  RetrieveUserDocumentAdminParams,
  PayrollAdditionalInfoModel,
  UpdatePayrollInfoParams,
  UploadProfilePictureAdminImageBody,
  PersonDataRequest,
} from '../..';
import retrieveAllPersonMutator from '../../../../utils/HttpClientOrval';
import deleteMultiplePersonMutator from '../../../../utils/HttpClientOrval';
import isExistingEmailMutator from '../../../../utils/HttpClientOrval';
import retrievePersonByGuidMutator from '../../../../utils/HttpClientOrval';
import updatePersonByGUIDMutator from '../../../../utils/HttpClientOrval';
import deletePersonMutator from '../../../../utils/HttpClientOrval';
import saveAdditionalInformationAdminMutator from '../../../../utils/HttpClientOrval';
import deleteDocumentAdminMutator from '../../../../utils/HttpClientOrval';
import retrieveSignedOriginalDocumentAdminMutator from '../../../../utils/HttpClientOrval';
import retrieveUserDocumentAdminMutator from '../../../../utils/HttpClientOrval';
import getPayrollInfoMutator from '../../../../utils/HttpClientOrval';
import updatePayrollInfoMutator from '../../../../utils/HttpClientOrval';
import retrieveProfilePictureAdminMutator from '../../../../utils/HttpClientOrval';
import uploadProfilePictureAdminMutator from '../../../../utils/HttpClientOrval';
import pushPersonDataMutator from '../../../../utils/HttpClientOrval';
import pushPersonDataManualMutator from '../../../../utils/HttpClientOrval';
import reRegisterPersonByGuidMutator from '../../../../utils/HttpClientOrval';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * (ADMIN) Retrieves all candidate profiles by list of statues
 * @summary Retrieve all candidate profiles by list of statues
 */
export const retrieveAllPerson = (
  params?: RetrieveAllPersonParams,
  options?: SecondParameter<typeof retrieveAllPersonMutator>
) => {
  return retrieveAllPersonMutator<PersonModel[]>(
    { url: `/admin/person`, method: 'get', params },
    options
  );
};
/**
 * (ADMIN) Delete multiple candidate profiles by GUID
 * @summary Delete multiple candidate profiles by GUID
 */
export const deleteMultiplePerson = (
  params: DeleteMultiplePersonParams,
  options?: SecondParameter<typeof deleteMultiplePersonMutator>
) => {
  return deleteMultiplePersonMutator<void>(
    { url: `/admin/person/delete`, method: 'delete', params },
    options
  );
};
/**
 * @summary checks if the current email is available for creation
 */
export const isExistingEmail = (
  emailValidationRequest: EmailValidationRequest,
  options?: SecondParameter<typeof isExistingEmailMutator>
) => {
  return isExistingEmailMutator<EmailValidationResponse>(
    {
      url: `/admin/person/is-existing-email}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: emailValidationRequest,
    },
    options
  );
};
/**
 * (ADMIN) Retrieves candidate profile by GUID
 * @summary Retrieve candidate profile by GUID
 */
export const retrievePersonByGuid = (
  guid: string,
  options?: SecondParameter<typeof retrievePersonByGuidMutator>
) => {
  return retrievePersonByGuidMutator<PersonModel>(
    { url: `/admin/person/${guid}`, method: 'get' },
    options
  );
};
/**
 * (ADMIN) Update candidate profile by GUID
 * @summary Update candidate profile by GUID
 */
export const updatePersonByGUID = (
  guid: string,
  personUpdateModelBody: PersonUpdateModelBody,
  params?: UpdatePersonByGUIDParams,
  options?: SecondParameter<typeof updatePersonByGUIDMutator>
) => {
  return updatePersonByGUIDMutator<PersonModel>(
    {
      url: `/admin/person/${guid}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: personUpdateModelBody,
      params,
    },
    options
  );
};
/**
 * (ADMIN) Delete candidate profile by GUID
 * @summary Delete candidate profile by GUID
 */
export const deletePerson = (
  guid: string,
  options?: SecondParameter<typeof deletePersonMutator>
) => {
  return deletePersonMutator<void>(
    { url: `/admin/person/${guid}`, method: 'delete' },
    options
  );
};
/**
 * (ADMIN) Add arbitrary key-value pairs against a candidate
 * @summary Add additional information against a candidate
 */
export const saveAdditionalInformationAdmin = (
  guid: string,
  additionalInformationModelArrayBody: AdditionalInformationModelArrayBody,
  params?: SaveAdditionalInformationAdminParams,
  options?: SecondParameter<typeof saveAdditionalInformationAdminMutator>
) => {
  return saveAdditionalInformationAdminMutator<PersonModel>(
    {
      url: `/admin/person/${guid}/additional-information`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: additionalInformationModelArrayBody,
      params,
    },
    options
  );
};
/**
 * (ADMIN) Delete document by document GUID
 * @summary Delete user document by document GUID
 */
export const deleteDocumentAdmin = (
  guid: string,
  docGuid: string,
  params?: DeleteDocumentAdminParams,
  options?: SecondParameter<typeof deleteDocumentAdminMutator>
) => {
  return deleteDocumentAdminMutator<PersonModel>(
    {
      url: `/admin/person/${guid}/document/${docGuid}`,
      method: 'delete',
      params,
    },
    options
  );
};
/**
 * Retrieve signed original document provided to the candidate to amend and upload
 * @summary Retrieve signed original document
 */
export const retrieveSignedOriginalDocumentAdmin = (
  guid: string,
  docGuid: string,
  params: RetrieveSignedOriginalDocumentAdminParams,
  options?: SecondParameter<typeof retrieveSignedOriginalDocumentAdminMutator>
) => {
  return retrieveSignedOriginalDocumentAdminMutator<ResponseEntity>(
    {
      url: `/admin/person/${guid}/document/${docGuid}/signed`,
      method: 'get',
      params,
    },
    options
  );
};
/**
 * Retrieve user amended document provided by the candidate previously
 * @summary Retrieve user amended document
 */
export const retrieveUserDocumentAdmin = (
  guid: string,
  docGuid: string,
  params: RetrieveUserDocumentAdminParams,
  options?: SecondParameter<typeof retrieveUserDocumentAdminMutator>
) => {
  return retrieveUserDocumentAdminMutator<ResponseEntity>(
    {
      url: `/admin/person/${guid}/document/${docGuid}/user`,
      method: 'get',
      params,
    },
    options
  );
};
/**
 * (ADMIN) Get candidate's payroll additional info
 * @summary Get candidate's payroll additional info
 */
export const getPayrollInfo = (
  guid: string,
  options?: SecondParameter<typeof getPayrollInfoMutator>
) => {
  return getPayrollInfoMutator<PayrollAdditionalInfoModel>(
    { url: `/admin/person/${guid}/payroll/info`, method: 'get' },
    options
  );
};
/**
 * (ADMIN) Update candidate's payroll additional info
 * @summary Update candidate's payroll additional info
 */
export const updatePayrollInfo = (
  guid: string,
  payrollAdditionalInfoModel: PayrollAdditionalInfoModel,
  params?: UpdatePayrollInfoParams,
  options?: SecondParameter<typeof updatePayrollInfoMutator>
) => {
  return updatePayrollInfoMutator<void>(
    {
      url: `/admin/person/${guid}/payroll/info`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: payrollAdditionalInfoModel,
      params,
    },
    options
  );
};
/**
 * (ADMIN) Retrieve profile picture of candidate
 * @summary Retrieve profile picture of candidate
 */
export const retrieveProfilePictureAdmin = (
  guid: string,
  options?: SecondParameter<typeof retrieveProfilePictureAdminMutator>
) => {
  return retrieveProfilePictureAdminMutator<string>(
    { url: `/admin/person/${guid}/profile-image`, method: 'get' },
    options
  );
};
/**
 * (ADMIN) Upload profile picture of candidate
 * @summary Upload profile picture of candidate
 */
export const uploadProfilePictureAdmin = (
  guid: string,
  uploadProfilePictureAdminImageBody: UploadProfilePictureAdminImageBody,
  options?: SecondParameter<typeof uploadProfilePictureAdminMutator>
) => {
  return uploadProfilePictureAdminMutator<ResponseEntity>(
    {
      url: `/admin/person/${guid}/profile-image`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: uploadProfilePictureAdminImageBody,
    },
    options
  );
};
/**
 * (ADMIN) Trigger push of stored candidate data to complete onboarding of candidate
 * @summary Trigger push of candidate data
 */
export const pushPersonData = (
  guid: string,
  options?: SecondParameter<typeof pushPersonDataMutator>
) => {
  return pushPersonDataMutator<PersonDataRequest>(
    { url: `/admin/person/${guid}/push`, method: 'post' },
    options
  );
};
/**
 * (ADMIN) Manually push custom candidate data to complete onboarding of candidate
 * @summary Manually push custom candidate data
 */
export const pushPersonDataManual = (
  guid: string,
  personDataRequest: PersonDataRequest,
  options?: SecondParameter<typeof pushPersonDataManualMutator>
) => {
  return pushPersonDataManualMutator<PersonDataRequest>(
    {
      url: `/admin/person/${guid}/push/manual`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: personDataRequest,
    },
    options
  );
};
/**
 * (ADMIN) Re-register candidate in AWS by GUID
 * @summary Re-register candidate in AWS by GUID
 */
export const reRegisterPersonByGuid = (
  guid: string,
  options?: SecondParameter<typeof reRegisterPersonByGuidMutator>
) => {
  return reRegisterPersonByGuidMutator<boolean>(
    { url: `/admin/person/${guid}/re-register`, method: 'post' },
    options
  );
};
