import { submitTfnd, validateTfn } from 'types/api/services/tfn/tfn';

/** TfnService */
export class TfnService {
  /** Validate TFN of logged in candidate: Validate TFN of logged in candidate using ATO EmployerTICK service */
  static validateTfnResponse = validateTfn;

  /** Submit TFN Declaration for logged in candidate : Submit TFN Declaration directly to ATO for logged in candidate */
  static declarationPerson = submitTfnd;
}
