/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Onboarding
 * OpenAPI spec version: 0.1
 */
import type { TaskConfigModel, GetDocumentTemplateParams } from '../..';
import getTasksMutator from '../../../../utils/HttpClientOrval';
import getDocumentTemplateMutator from '../../../../utils/HttpClientOrval';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * (ADMIN) Retrieve the list of tasks available for assigning to flows
 * @summary Get tasks
 */
export const getTasks = (options?: SecondParameter<typeof getTasksMutator>) => {
  return getTasksMutator<TaskConfigModel[]>(
    { url: `/task`, method: 'get' },
    options
  );
};
/**
 * (ADMIN) Retrieve template associated with template id. If candidate GUID is provided, annotated fields will be populated with available data
 * @summary Get document template
 */
export const getDocumentTemplate = (
  templateId: number,
  params?: GetDocumentTemplateParams,
  options?: SecondParameter<typeof getDocumentTemplateMutator>
) => {
  return getDocumentTemplateMutator<string>(
    { url: `/task/document-template/${templateId}`, method: 'get', params },
    options
  );
};
