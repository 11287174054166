import {
  retrieveBusinessDetails,
  updateBusinessDetails,
} from 'types/api/services/business-details/business-details';

/** BusinessDetailsService */
export class BusinessDetailsService {
  /** Retrieve saved business details */
  static retrieveBusinessDetails = retrieveBusinessDetails;

  /** Update business details */
  static updateBusinessDetails = updateBusinessDetails;
}
