import { Auth } from 'aws-amplify';

import clearStorageData from './clearStorageData';

const signOut = async () => {
  try {
    await Auth.signOut();
    clearStorageData();
    window.location.replace(window.location.origin);
  } catch (error) {
    console.log('error signing out: ', error);
  }
};

export default signOut;
